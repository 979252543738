import React from 'react';
import '../../style/track-order.scss'
import {Nav} from "../../components";
import Footer from "../../components/footer";

const PrivacyPolicy = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Swaptecs Privacy Policy'}
            darkNav={true}
        />

        <div className="pageContainer trackOrder">
            <section className={'text-section'}>
                <h1>Swaptecs Privacy Policy</h1>
                <p className="color-waterloo">
                    The Swaptecs Privacy Policy explains how personal information about you is collected, used and
                    disclosed by Swaptecs, LLC (doing business as Swaptecs) ("Swaptecs", "we" or "us") when you access
                    and use Swaptecs’s websites, applications and services that link to this Privacy Policy
                    (collectively, the "Services"). This Privacy Policy does not apply to any other kiosks, websites or
                    other online or mobile services or applications of Swaptecs, LLC or its subsidiaries or affiliates.
                </p>
            </section>

            <section className={'info-text'}>
                <h2>Personal Information Collection</h2>
                <h3>Information You Provide to Us.</h3>
                <p>
                    We collect information you provide directly to us. For example, when you transact with us (e.g.,
                    offer to sell, donate, ship, or purchase a device) or register to open an account with Swaptecs, we
                    ask for information about yourself, such as your name, email address, and zip code, which we collect
                    and use as disclosed in this Privacy Policy. We may also collect additional information directly
                    from you when you engage in certain activities on the Services, such as: (a) when you sign up for
                    special offers; (b) when you contact us for help or customer support; (c) when you complete a
                    survey; (d) when you post content or provide us with feedback; or (e) when you enter promotions or
                    sweepstakes.
                </p>
                <p>
                    Providing information is purely optional on your part; however, not all features and services (such
                    as, responding to your inquiries) would be available to you if you elect not to provide information.
                    If you choose to provide us with personal information, you consent to the transfer and storage of
                    that information by us.
                </p>
            </section>

            <section className={'info-text'}>
                <h3>Information We Collect Automatically.</h3>
                <p>We automatically collect information about you when you use our Services. For example, we may collect:</p>
                <p>• Transactional Data: When you sell, donate, ship, purchase, or otherwise transact using the Services, we collect information about the transaction including device specifications, purchase price, and date and location of the transaction.</p>
                <p>• Device Information: We collect information about the device you use to access our Services, including the hardware model, operating system and version, and unique device identifiers.</p>
                <p>• Location Information: We may derive the approximate location of your device from your IP address.</p>
                <p>• Log and Usage Information: We automatically receive and collect information on our server logs from your browser, including your IP address, the Uniform Resource Locator ("URL") that you just came from, which URL you next go to, browser type, browser language, date and time of your query, Swaptecs cookie information, and the pages you request.</p>
                <p>• Information Collected by Cookies and Similar Tracking Technologies: We (and our service providers) use different technologies to collect information, including cookies and web beacons. For more information about cookies and how to disable them, see “Cookies and Other Tracking Technologies” below.</p>
                <p>Information We Collect From Other Sources. For example, we may collect information about you from third parties, including but not limited to credit bureaus and publicly available sources. Additionally, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, account information, and friends lists, in accordance with the authorization procedures determined by such social media site.</p>
                <p>Information We Derive. We may derive information or draw inferences about you based on the information we collect. For example, we may make inferences about your location based on your IP address.</p>
            </section>

            <section className={'info-text'}>
                <h3>Use of Personal Information</h3>
                <p>
                    We primarily will use your personal information to provide the Services to you. We also use your
                    personal information to: (a) send you marketing messages and to customize, measure, and analyze the
                    advertising and content you see; (b) fulfill any transactions, including your requests for products
                    and services; (c) improve our Services and offerings to you; (d) conduct research (for example, we
                    may contact you for feedback about our products); (e) provide you with support; (f) provide you with
                    updates and notices about the Services, our Privacy Policy and terms and conditions; (g) communicate
                    with you regarding the Services; (h) provide you with offers from us, our affiliates and from third
                    parties that may be of interest to you; (i) facilitate contests, sweepstakes, and promotions and
                    process and deliver entries and rewards; (j) investigate fraud and for security purposes, and where
                    necessary to protect ourselves and third parties; (k) comply with our legal and regulatory
                    obligations; (l) diagnose problems with our servers and software; and (m) with your consent or at
                    your direction.
                </p>
            </section>

            <section className={'info-text'}>
                <h3>
                    Cookies and Other Tracking Technologies
                </h3>
                <p>We use various technologies to collect information, and this may include placing cookies on your computer. A "cookie" is a small file containing a string of characters that is sent to your computer and stored on your hard drive when you visit a website; many major Web services use them to provide useful features for their users. Each web site can send its own cookie to your browser. Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent; however if you reject cookies, you will not be able to sign in to the Services or take full advantage of our Services. Swaptecs uses cookies for the following general purposes: (a) to provide you with your personalized content; (b) to display the most appropriate advertisements, based on your interests and activity on our web site; and (c) and to help us analyze and estimate our traffic and use of features on the Services.</p>
                <p>We may also use "pixel tags," which are small graphic files that can collect information such as the IP address of the computer that downloaded the page on which the tag appears; the URL of the page on which the pixel tag appears; the time (and length of time) the page containing the pixel tag was viewed; the type of browser that retrieved the pixel tag; and the identification number of any cookie previously placed by that server on your computer. We may use pixel tags, either provided by us or by our third party advertisers and ad networks, to collect information about your visit, including the pages you view, the links you click and other actions taken in connection with our sites and Services and use them in combination with our cookies to provide offers and information of interest to you.</p>
                <p>We also may allow certain analytic services and providers of applications used on the Services (e.g. sharing buttons) to collect information by placing cookies on the Services that will track certain performance of our Services, such as, web traffic, click-throughs, etc., in order to assist us and our service providers in better understanding the interests of our users and to better serve those interests.</p>
            </section>

            <section className={'info-text'}>
                <h3>Third-Party Advertising and Analytics</h3>

                <p>We may use other companies under agreements with us to serve advertisements on our behalf and to provide analytics services. These companies may collect and use information about your visits to the Services and other Web sites (including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information) in order to, among other things, provide advertisements about goods and services likely to be of greater interest to you and to better understand your online activities. These companies typically use a cookie, a third party web beacon and/or pixel tags to collect this information. To learn more about this behavioral advertising practice in general and the options that you have, including your option or to opt-out of this type of advertising, you can visit www.aboutads.info/choices.</p>
                <p>We may also work with third parties to serve ads to you as part of a customized campaign on third-party platforms (such as Facebook or Instagram). As part of these ad campaigns, we or third-party platforms may convert information about you, such as your email address and phone number, into a unique value that can be matched with a user account on these platforms to allow us to learn about your interests and to serve you advertising that is customized to your interests. Note that the third-party platforms may offer you choices about whether you see these types of customized ads.</p>
            </section>

            <section className={'info-text'}>
                <h3>Personal Information Sharing and Disclosure</h3>

                <p>Swaptecs does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide products or services you've requested, when we have your permission, or under the following circumstances:</p>
                <p>• To vendors, service providers, and consultants who work on behalf of Swaptecs and perform services for us under confidentiality agreements, including payment processing, assisting with promotions, and providing technical services;</p>
                <p>• In response to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims;</p>
                <p>• When we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Swaptecs’s terms of use, or as otherwise required by law; and</p>
                <p>• We may transfer information about you if Swaptecs is acquired by or merged with another company. In this event, Swaptecs will post a notice on its website before information about you is transferred and becomes subject to a different privacy policy.</p>
                <p>We may also share aggregated or de-identified information that cannot reasonably be used by third parties to identify you.</p>
            </section>

            <section className={'info-text'}>
                <h3>Links to Third Party Sites</h3>
                <p>The sites displayed as search results or linked to by the Services are developed by people over whom Swaptecs exercises no control. These other sites may place their own cookies on your computer, collect data or solicit personal information. Swaptecs may present links in a format that enables us to understand whether they have been followed. We use this information to understand and improve the quality of Swaptecs’s search technology. For instance, this data helps us determine how often users are satisfied with the first result of a query and how often they proceed to later results.</p>
            </section>

            <section className={'info-text'}>
                <h3>Social Media Plug-ins</h3>

                <p>Social media plug-ins of social networks such as Facebook, Twitter, LinkedIn, Yahoo, Windows and Google plus (among others) are integrated on Swaptecs’s website. Where Swaptecs sites contain a plug-in to a social network, these are clearly marked (e.g. with a Facebook button). If you chose to click on one of these buttons or links, your browser connects directly to the servers of the relevant social network. The social network directly transmits the content of the plugin to your browser. If you are registered on the relevant social network and logged into your respective account the social network receives the information that the web page was used by you. If you interact with a social network plug-in (e.g. you press the Facebook "Like" feature, the Twitter "Tweet this" feature and Google plus "1+" button or equivalent) or drop a comment on the appropriate web page, the corresponding information is directly transmitted to the relevant social network from your browser. If you are not registered with the social network or you are logged out before you visit Swaptecs’s website, there is the possibility that at least your IP address will be submitted to and stored by the social network. If you interact with us through a social media platform, plug-in etc. then you may be permitting us to have on-going access to some limited information from your social network profile (such as name, email address, photo, gender, location, the people/sites you follow etc.). If you do not want the social network to collect the information about you described above, or to share it with Swaptecs and other third parties, please review the privacy policy of the relevant social network and/or log out of the relevant social network before you visit Swaptecs’s site. As with other sites, you may be able delete any existing cookies placed on your computer by the social network via your browser.</p>
            </section>

            {/*<section className={'info-text'}>*/}
            {/*    <h3>Your Ability to Edit and Delete Your Account Information and Preferences</h3>*/}

            {/*    <p>Registered users can edit their Swaptecs account information, including contact preferences, at any time from their "My Account" account management page which you can find at www.swaptecs.com/account/login. We may, from time to time, send you e-mails regarding our products and services and third party products and services that may be of interest to you. You will have the opportunity to opt out of any further mailings at any time; provided, however, that we reserve the right to send you certain communications relating to the Services, such as (a) communications related to fulfilling a transaction, (b) service announcements, (c) notices regarding Swaptecs’s legal terms and this Privacy Policy; and (d) administrative messages, which are considered part of your Swaptecs account. You will not have the opportunity to opt-out of receiving such notices. You can delete your Swaptecs registered account by requesting an account deletion via email to support@swaptecs.com. You must provide us with the email address of the registered account. After we have confirmed your request via email, we will remove access to your personal information from our user database.</p>*/}
            {/*</section>*/}

            <section className={'info-text'}>
                <h3>Confidentiality and Security</h3>
                <p>We limit access to personal information about you to our employees and agents who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs. Your information is stored on our servers located in the United States. We treat data as an asset that must be protected and use many tools (encryption, passwords, physical security, etc.) to protect your personal information against unauthorized access and disclosure. However, third parties may unlawfully intercept or access transmissions or private communications, and other users may abuse or misuse your personal information that they collect from the site. Therefore, although we work very hard to protect your privacy, we do not promise, and you should not expect, that your personal information or private communications will always remain private. Because email and instant messaging are not recognized as secure communications, we request that you not send private information to us by email or instant messaging services.</p>
            </section>

            <section className={'info-text'}>
                <h3>Third Parties</h3>
                <p>This Privacy Policy only addresses the use and disclosure of information we collect from you. This policy does not apply to the practices of companies that Swaptecs does not own or control or to people that Swaptecs does not employ or manage. The Services may include applications, content and/or ads from third parties that may perform user activity tracking in addition to that described herein; Swaptecs accepts no responsibility for said activities. Swaptecs does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. We encourage you to ask questions before you disclose your personal information to others. For more information about each such third party, please refer to the third party's privacy policy.</p>
            </section>

            <section className={'info-text'}>
                <h3>Changes to this Privacy Policy</h3>
                <p>We may amend this Privacy Policy at any time by posting the amended terms on this site. In addition, we will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your Swaptecs account or by placing a prominent notice on our site.</p>
            </section>

            <section className={'info-text'}>
                <h3>Children</h3>
                <p>Swaptecs is intended for general audiences. Swaptecs does not knowingly collect or maintain information from users under 13, and no part of the Swaptecs Services are targeted to attract anyone under 13.</p>
            </section>

            <section className={'info-text'}>
                <h3>Your California Privacy Rights</h3>
                <p>Consumers residing in California are afforded certain additional rights with respect to their personal information under the California Consumer Privacy Act (California Civil Code Section 1798.100 et seq. (“CCPA”). If you are a California resident, this section applies to you.</p>
                <p>Collection of Personal Information. In the last 12 months, we collected the following categories of personal information: identifiers (such as name, contact information, and government-issued identification number), commercial information (such as time, date and location of a transaction), location information, internet or other electronic network activity information (such as browsing behavior), demographic information (such as data gathered from credit bureaus), audio, electronic, visual or similar information (such as customer support calls), and inferences we make. For more details about the personal information we collect, including the categories of sources, please see “Personal Information Collection” above. Use and Disclosure of Personal Information. We collect the categories of personal information listed above for the business and commercial purposes described in “Use of Personal Information.” We share this information with the categories of third parties described in “Personal Information Sharing and Disclosure” above.</p>
                <p>Sales of Personal Information. California law requires that we provide transparency about personal information we “sell,” which for purposes of the CCPA broadly means scenarios in which we have shared personal information with third parties in exchange for valuable consideration. We do not, and will not, sell your identifying information (such as your name, email address, phone number, or postal address); however, as described above in “Cookies and Other Tracking Technologies” and “Third-Party Advertising and Analytics,” we do allow our advertising partners to use cookies and other technologies to collect personal information about you in order to show you advertisements that are targeted to your interests. In order to opt out of disclosures to these third parties for targeted advertising, please follow the opt out instructions in the “Third-Party Advertising and Analytics” section above.</p>
                <p>Your Consumer Rights: Subject to certain limitations, the CCPA provides California consumers the right to request to know more details about the categories and specific pieces of personal information we collect, to delete their personal information, to opt out of any “sales” that may be occurring, and to not be discriminated against for exercising these rights. California consumers may make a rights request by visiting this page (https://www.swaptecs.com/help/send_ticket). We will verify your request by asking you to provide identifying information and information related to your recent interactions with us. If you would like to use an authorized agent registered with the California Secretary of State to exercise your rights, we may request evidence that you have provided such agent with power of attorney or that the agent otherwise has valid written authority to submit requests on your behalf. We will not discriminate against you if you exercise your rights under the CCPA.</p>
            </section>

            {/*<section className={'info-text'}>*/}
            {/*    <h3>Notice to Nevada Residents</h3>*/}
            {/*    <p>We do not currently sell your covered information as those terms are defined under applicable Nevada law. You may still submit an opt-out request and we will honor that request as required by Nevada law if we were to engage in such a sale in the future. If you are a Nevada resident and would like to opt-out of the sale of your covered information, please submit your request by calling 1-800-429-3553 or visiting this page (https://www.swaptecs.com/help/send_ticket). You may also be required to take reasonable steps as we determine from time to time in order to verify your identity and/or the authenticity of the request.</p>*/}
            {/*</section>*/}

            <section className={'info-text'}>
                <h3>Request for Information</h3>
                <p>If you have questions, concerns or suggestions, please feel free to send an email to contact@swaptecs.com. </p>
            </section>
        </div>

        <Footer />
    </div>
}

export default PrivacyPolicy;